// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';

export const environment = {
  production: true,
  google_sso_client_id: '429037162005-1sv45okdesh5at4q2o096o1l2893vo89.apps.googleusercontent.com',
  version: env.npm_package_version,
  serverUrl: '',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  enableSlackRedirect: true,
  slackClientID: '109465824896.397242901826',
  slackAppScope:
    'chat:write,commands,emoji:read,files:read,groups:history,groups:read,groups:write,mpim:history,mpim:read,mpim:write,reactions:read,remote_files:read,remote_files:write,team:read,users.profile:read,users:read,users:read.email,users:write'
};
